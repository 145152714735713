export default class Relationship {
    constructor(parent, term, relation) {
        this.parent = parent;
        this.relation = relation;
        this.term = term;
        this.id = Relationship.refId++;
    }
    toJson() {
        return {
            term: this.term.name,
            relation: this.relation
        };
    }
}
Relationship.refId = 0;
