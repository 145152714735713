var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _TermManager_instances, _TermManager_toggleAllSubElements, _TermManager_toggleMainForm, _TermManager_createCurrentTerm, _TermManager_updateMainFormColor, _TermManager_updateDisplay, _TermManager_addRelationship, _TermManager_addTerm, _TermManager_cancel, _TermManager_delete, _TermManager_deleteRel, _TermManager_save, _TermManager_load, _TermManager_clear, _TermManager_compareStrings, _TermManager_getStringDistance, _TermManager_updateRelations, _TermManager_addRelation, _TermManager_getRelation, _TermManager_nameField, _TermManager_descriptionField, _TermManager_idField, _TermManager_relationField, _TermManager_termField, _TermManager_termDiv, _TermManager_relationshipContainer, _TermManager_relationshipReverseContainer, _TermManager_terms, _TermManager_currentTerm, _TermManager_isShiftPressed, _TermManager_mainFormStatus, _TermManager_relations;
import Relation from "../relation";
import Relationship from "../relationship";
import { utils } from "../utils";
import Term from "./term";
var levenshtein = require('fast-levenshtein');
var vis = require('vis-data');
var visn = require('vis-network');
export default class TermManager {
    constructor() {
        _TermManager_instances.add(this);
        _TermManager_nameField.set(this, void 0);
        _TermManager_descriptionField.set(this, void 0);
        _TermManager_idField.set(this, void 0);
        _TermManager_relationField.set(this, void 0);
        _TermManager_termField.set(this, void 0);
        _TermManager_termDiv.set(this, void 0);
        _TermManager_relationshipContainer.set(this, void 0);
        _TermManager_relationshipReverseContainer.set(this, void 0);
        _TermManager_terms.set(this, void 0);
        _TermManager_currentTerm.set(this, void 0);
        _TermManager_isShiftPressed.set(this, void 0);
        _TermManager_mainFormStatus.set(this, void 0);
        _TermManager_relations.set(this, []);
        __classPrivateFieldSet(this, _TermManager_nameField, window.document.getElementById("name"), "f");
        __classPrivateFieldSet(this, _TermManager_descriptionField, window.document.getElementById("description"), "f");
        __classPrivateFieldSet(this, _TermManager_idField, window.document.getElementById("id"), "f");
        __classPrivateFieldSet(this, _TermManager_relationField, window.document.getElementById("relation"), "f");
        __classPrivateFieldSet(this, _TermManager_termField, window.document.getElementById("term"), "f");
        __classPrivateFieldSet(this, _TermManager_termDiv, window.document.getElementById("allTerms"), "f");
        __classPrivateFieldSet(this, _TermManager_relationshipContainer, window.document.getElementById("containerRelationship"), "f");
        __classPrivateFieldSet(this, _TermManager_relationshipReverseContainer, window.document.getElementById("containerReverseRelationship"), "f");
        window.document.getElementById("addRelation").onclick = () => { __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_addRelation).call(this); };
        window.document.getElementById("addTerm").onclick = () => { __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_addTerm).call(this); };
        window.document.getElementById("addRelationship").onclick = () => { __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_addRelationship).call(this); };
        __classPrivateFieldGet(this, _TermManager_nameField, "f").oninput = () => { __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_updateMainFormColor).call(this, __classPrivateFieldGet(this, _TermManager_nameField, "f").value); };
        __classPrivateFieldGet(this, _TermManager_termField, "f").oninput = () => { __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_updateMainFormColor).call(this, __classPrivateFieldGet(this, _TermManager_termField, "f").value); };
        __classPrivateFieldSet(this, _TermManager_terms, [], "f");
        window.document.getElementById("saveTerm").onclick = () => { __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_save).call(this); };
        window.document.getElementById("cancelTerm").onclick = () => { __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_cancel).call(this); };
        __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_createCurrentTerm).call(this);
        __classPrivateFieldSet(this, _TermManager_terms, [], "f");
        __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_toggleMainForm).call(this, true);
        __classPrivateFieldSet(this, _TermManager_isShiftPressed, false, "f");
    }
    onKeyDown(e) {
        if (e.key === "Shift") {
            __classPrivateFieldSet(this, _TermManager_isShiftPressed, true, "f");
        }
    }
    onKeyUp(e) {
        if (e.key === "Shift") {
            __classPrivateFieldSet(this, _TermManager_isShiftPressed, false, "f");
        }
    }
    updateTermList(terms) {
        __classPrivateFieldSet(this, _TermManager_terms, terms, "f");
        __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_toggleMainForm).call(this, true);
        __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_updateDisplay).call(this, "");
    }
    getTermList() {
        return __classPrivateFieldGet(this, _TermManager_terms, "f");
    }
    getRelations() {
        return __classPrivateFieldGet(this, _TermManager_relations, "f");
    }
    setRelations(relations) {
        __classPrivateFieldSet(this, _TermManager_relations, relations, "f");
        __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_updateRelations).call(this);
    }
}
_TermManager_nameField = new WeakMap(), _TermManager_descriptionField = new WeakMap(), _TermManager_idField = new WeakMap(), _TermManager_relationField = new WeakMap(), _TermManager_termField = new WeakMap(), _TermManager_termDiv = new WeakMap(), _TermManager_relationshipContainer = new WeakMap(), _TermManager_relationshipReverseContainer = new WeakMap(), _TermManager_terms = new WeakMap(), _TermManager_currentTerm = new WeakMap(), _TermManager_isShiftPressed = new WeakMap(), _TermManager_mainFormStatus = new WeakMap(), _TermManager_relations = new WeakMap(), _TermManager_instances = new WeakSet(), _TermManager_toggleAllSubElements = function _TermManager_toggleAllSubElements(elem, state) {
    for (let e of elem.getElementsByTagName("button")) {
        e.disabled = state;
    }
    for (let e of elem.getElementsByTagName("input")) {
        e.disabled = state;
    }
    for (let e of elem.getElementsByTagName("textarea")) {
        e.disabled = state;
    }
    for (let e of elem.getElementsByTagName("select")) {
        e.disabled = state;
    }
}, _TermManager_toggleMainForm = function _TermManager_toggleMainForm(state) {
    __classPrivateFieldSet(this, _TermManager_mainFormStatus, state, "f");
    for (let item of document.getElementsByClassName("mainForm")) {
        if (state)
            item.classList.remove("disabled");
        else
            item.classList.add("disabled");
        __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_toggleAllSubElements).call(this, item, !state);
    }
    for (let item of document.getElementsByClassName("subForm")) {
        if (state)
            item.classList.add("disabled");
        else
            item.classList.remove("disabled");
        __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_toggleAllSubElements).call(this, item, state);
    }
}, _TermManager_createCurrentTerm = function _TermManager_createCurrentTerm() {
    __classPrivateFieldSet(this, _TermManager_currentTerm, new Term(), "f");
    __classPrivateFieldGet(this, _TermManager_idField, "f").innerHTML = __classPrivateFieldGet(this, _TermManager_currentTerm, "f").id.toString();
}, _TermManager_updateMainFormColor = function _TermManager_updateMainFormColor(comparer) {
    __classPrivateFieldGet(this, _TermManager_termDiv, "f").innerHTML = __classPrivateFieldGet(this, _TermManager_terms, "f").map(t => {
        return t.toHtml(__classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_getStringDistance).call(this, t.name, comparer), __classPrivateFieldGet(this, _TermManager_mainFormStatus, "f"));
    }).join('');
    if (comparer !== "") {
        __classPrivateFieldSet(this, _TermManager_terms, __classPrivateFieldGet(this, _TermManager_terms, "f").sort((a, b) => __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_getStringDistance).call(this, a.name, comparer) - __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_getStringDistance).call(this, b.name, comparer)), "f");
    }
}, _TermManager_updateDisplay = function _TermManager_updateDisplay(comparer) {
    __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_updateMainFormColor).call(this, comparer);
    for (let t of __classPrivateFieldGet(this, _TermManager_terms, "f")) {
        document.getElementById(`term-${t.id}`).onclick = () => { __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_load).call(this, t.id); };
        document.getElementById(`delete-${t.id}`).onclick = () => { __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_delete).call(this, t.id); };
    }
    if (__classPrivateFieldGet(this, _TermManager_currentTerm, "f") === null) {
        __classPrivateFieldGet(this, _TermManager_relationshipContainer, "f").innerHTML = "";
        __classPrivateFieldGet(this, _TermManager_relationshipReverseContainer, "f").innerHTML = "";
    }
    else {
        __classPrivateFieldGet(this, _TermManager_relationshipContainer, "f").innerHTML = __classPrivateFieldGet(this, _TermManager_currentTerm, "f").relationships.map(r => {
            return `<tr><td>${__classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_getRelation).call(this, r.relation)}</td><td>${utils.escape(r.term.name)}</td><td><button id="relDelete-${r.id}">Delete</button></td></tr>`;
        }).join('');
        __classPrivateFieldGet(this, _TermManager_relationshipReverseContainer, "f").innerHTML = __classPrivateFieldGet(this, _TermManager_terms, "f").flatMap(t => {
            return t.relationships;
        }).filter(r => {
            return r.term.name === __classPrivateFieldGet(this, _TermManager_nameField, "f").value;
        }).map(r => {
            return `<tr><td>${utils.escape(r.parent.name)}</td><td>${__classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_getRelation).call(this, r.relation)}</td><td></td></tr>`;
        }).join('');
        for (let r of __classPrivateFieldGet(this, _TermManager_currentTerm, "f").relationships) {
            document.getElementById(`relDelete-${r.id}`).onclick = () => { __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_deleteRel).call(this, r.id); };
        }
    }
    let nodes = [];
    let links = [];
    for (let t of __classPrivateFieldGet(this, _TermManager_terms, "f")) {
        nodes.push({ id: t.id, label: t.name });
        for (let r of t.relationships) {
            links.push({ from: t.id, to: r.term.id });
        }
    }
    let data = {
        nodes: new vis.DataSet(nodes),
        edges: new vis.DataSet(links)
    };
    let options = {};
    new visn.Network(document.getElementById("network"), data, options);
}, _TermManager_addRelationship = function _TermManager_addRelationship() {
    if (__classPrivateFieldGet(this, _TermManager_relationField, "f").value === "default" || __classPrivateFieldGet(this, _TermManager_termField, "f").value === "") {
        return;
    }
    let sameName = __classPrivateFieldGet(this, _TermManager_terms, "f").find(t => __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_compareStrings).call(this, t.name, __classPrivateFieldGet(this, _TermManager_termField, "f").value));
    if (sameName === undefined) {
        sameName = new Term(__classPrivateFieldGet(this, _TermManager_termField, "f").value);
        __classPrivateFieldGet(this, _TermManager_terms, "f").push(sameName);
    }
    __classPrivateFieldGet(this, _TermManager_currentTerm, "f").relationships.push(new Relationship(__classPrivateFieldGet(this, _TermManager_currentTerm, "f"), sameName, __classPrivateFieldGet(this, _TermManager_relationField, "f").value));
    __classPrivateFieldGet(this, _TermManager_termField, "f").value = "";
    __classPrivateFieldGet(this, _TermManager_relationField, "f").value = "default";
    __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_updateDisplay).call(this, "");
}, _TermManager_addTerm = function _TermManager_addTerm() {
    __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_toggleMainForm).call(this, false);
    __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_createCurrentTerm).call(this);
    __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_updateDisplay).call(this, "");
}, _TermManager_cancel = function _TermManager_cancel() {
    __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_clear).call(this);
    __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_toggleMainForm).call(this, true);
}, _TermManager_delete = function _TermManager_delete(id) {
    let term = __classPrivateFieldGet(this, _TermManager_terms, "f").find(t => t.id === id);
    if (__classPrivateFieldGet(this, _TermManager_isShiftPressed, "f") || confirm(`Are you sure you want to delete ${term.name}?\nThis action cannot be undone\n(Click this button while pressing shift to skip this dialog)`)) {
        __classPrivateFieldGet(this, _TermManager_terms, "f").splice(__classPrivateFieldGet(this, _TermManager_terms, "f").findIndex(i => i.id === term.id), 1);
        __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_updateDisplay).call(this, "");
    }
}, _TermManager_deleteRel = function _TermManager_deleteRel(id) {
    var _a, _b;
    let rel = (_a = __classPrivateFieldGet(this, _TermManager_currentTerm, "f")) === null || _a === void 0 ? void 0 : _a.relationships.find(r => r.id === id);
    if (__classPrivateFieldGet(this, _TermManager_isShiftPressed, "f") || confirm(`Are you sure you want to delete this relationship?\nThis action cannot be undone\n(Click this button while pressing shift to skip this dialog)`)) {
        (_b = __classPrivateFieldGet(this, _TermManager_currentTerm, "f")) === null || _b === void 0 ? void 0 : _b.relationships.splice(__classPrivateFieldGet(this, _TermManager_currentTerm, "f").relationships.findIndex(i => i.id === rel.id), 1);
        __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_updateDisplay).call(this, "");
    }
}, _TermManager_save = function _TermManager_save() {
    if (__classPrivateFieldGet(this, _TermManager_nameField, "f").value === "") {
        alert("Can't create a term with an empty name");
        return;
    }
    let sameName = __classPrivateFieldGet(this, _TermManager_terms, "f").find(t => __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_compareStrings).call(this, t.name, __classPrivateFieldGet(this, _TermManager_nameField, "f").value));
    if (sameName !== undefined && sameName.id !== __classPrivateFieldGet(this, _TermManager_currentTerm, "f").id) {
        alert("You already have a term with this name");
        return;
    }
    __classPrivateFieldGet(this, _TermManager_currentTerm, "f").name = __classPrivateFieldGet(this, _TermManager_nameField, "f").value;
    __classPrivateFieldGet(this, _TermManager_currentTerm, "f").description = __classPrivateFieldGet(this, _TermManager_descriptionField, "f").value;
    let current = __classPrivateFieldGet(this, _TermManager_terms, "f").find(t => t.id === __classPrivateFieldGet(this, _TermManager_currentTerm, "f").id);
    if (current === undefined) {
        __classPrivateFieldSet(this, _TermManager_terms, [__classPrivateFieldGet(this, _TermManager_currentTerm, "f"), ...__classPrivateFieldGet(this, _TermManager_terms, "f")], "f");
    }
    else {
        current.name = __classPrivateFieldGet(this, _TermManager_nameField, "f").value;
        current.description = __classPrivateFieldGet(this, _TermManager_descriptionField, "f").value;
        current.relationships = __classPrivateFieldGet(this, _TermManager_currentTerm, "f").relationships;
    }
    __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_clear).call(this);
    __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_toggleMainForm).call(this, true);
}, _TermManager_load = function _TermManager_load(id) {
    let term = __classPrivateFieldGet(this, _TermManager_terms, "f").find(t => t.id === id);
    __classPrivateFieldSet(this, _TermManager_currentTerm, term, "f");
    __classPrivateFieldGet(this, _TermManager_nameField, "f").value = term.name;
    __classPrivateFieldGet(this, _TermManager_descriptionField, "f").value = term.description;
    __classPrivateFieldGet(this, _TermManager_idField, "f").innerHTML = term.id.toString();
    __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_toggleMainForm).call(this, false);
    __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_updateDisplay).call(this, "");
}, _TermManager_clear = function _TermManager_clear() {
    __classPrivateFieldGet(this, _TermManager_nameField, "f").value = "";
    __classPrivateFieldGet(this, _TermManager_descriptionField, "f").value = "";
    __classPrivateFieldGet(this, _TermManager_relationField, "f").value = "default";
    __classPrivateFieldGet(this, _TermManager_termField, "f").value = "";
    __classPrivateFieldSet(this, _TermManager_currentTerm, null, "f");
    __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_updateDisplay).call(this, "");
}, _TermManager_compareStrings = function _TermManager_compareStrings(a, b) {
    return a.trim().toUpperCase() === b.trim().toUpperCase();
}, _TermManager_getStringDistance = function _TermManager_getStringDistance(a, b) {
    if (a === "" || b === "") {
        return Number.MAX_VALUE;
    }
    return levenshtein.get(a, b);
}, _TermManager_updateRelations = function _TermManager_updateRelations() {
    let htmlR = '<option disabled selected value="default" class="tr">Select Relationship</option>';
    let htmlL = '';
    for (let r of __classPrivateFieldGet(this, _TermManager_relations, "f")) {
        htmlR += `<option value="${r.id}">${r.name}</option>`;
        htmlL += r.name + "<br/>";
    }
    window.document.getElementById("relation").innerHTML = htmlR;
    window.document.getElementById("relationList").innerHTML = htmlL;
}, _TermManager_addRelation = function _TermManager_addRelation() {
    let text = window.document.getElementById("newRelation");
    if (text.value != "") {
        __classPrivateFieldGet(this, _TermManager_relations, "f").push(new Relation(text.value));
    }
    __classPrivateFieldGet(this, _TermManager_instances, "m", _TermManager_updateRelations).call(this);
    text.value = "";
}, _TermManager_getRelation = function _TermManager_getRelation(name) {
    for (let r of __classPrivateFieldGet(this, _TermManager_relations, "f")) {
        if (name === r.id) {
            return r.name;
        }
    }
    return null;
};
