import * as tr from "./translation/tr";
import * as saveload from "./saveload/saveload";
import TermManager from "./term/termManager";
function init() {
    tr.translateAll();
    termManager = new TermManager();
    window.document.getElementById("load").onclick = () => { saveload.loadFile(); };
    window.document.getElementById("save").onclick = () => { saveload.save(termManager.getTermList(), termManager.getRelations()); };
    window.document.getElementById("uploadInternal").addEventListener('change', () => {
        saveload.upload((terms, r) => {
            termManager.updateTermList(terms);
            termManager.setRelations(r);
        });
    });
    window.onkeydown = function (e) {
        if (e.ctrlKey && (e.key == 's' || e.key == 'S')) {
            e.preventDefault();
            saveload.save(termManager.getTermList(), termManager.getRelations());
        }
        termManager.onKeyDown(e);
    };
    window.onkeyup = function (e) {
        termManager.onKeyUp(e);
    };
}
let termManager;
window.onload = init;
