import SaveData from "./saveData";
import Term from "../term/term";
import Relationship from "../relationship";
export function loadFile() {
    window.document.getElementById("uploadInternal").click();
}
export function upload(onReadEnd) {
    const file = document.getElementById("uploadInternal").files[0];
    if (file) {
        const reader = new FileReader();
        reader.onload = function (val) {
            let userData = JSON.parse(val.target.result);
            if (userData.version !== SaveData.getVersion()) {
                alert("Your save file is using an older version of the website");
            }
            let json = userData.terms;
            let data = json.map(t => new Term(t.name, t.description));
            for (let i in json) {
                let t = json[parseInt(i)];
                let rData = [];
                for (let r of t.relationships) {
                    rData.push(new Relationship(t, data.find(x => r.term == x.name), r.relation));
                }
                data[parseInt(i)].relationships = rData;
            }
            onReadEnd(data, userData.relations);
        };
        reader.readAsText(file);
    }
}
export function save(terms, relations) {
    const a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(new Blob([JSON.stringify(new SaveData(terms, relations))], { type: 'application/json' }));
    a.download = 'save.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
