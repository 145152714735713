export function translateAll() {
    [].forEach.call(document.getElementsByClassName("tr"), function (e) {
        if (e.localName === "input") {
            let input = e;
            input.placeholder = input.placeholder;
        }
        else {
            e.innerHTML = e.innerHTML;
        }
    });
}
