var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Term_instances, _Term_distanceToColor;
import { utils } from "../utils";
export default class Term {
    constructor(name = "", description = "") {
        _Term_instances.add(this);
        this.relationships = [];
        this.name = name;
        this.description = description;
        this.relationships = [];
        this.id = Term.refId++;
    }
    toHtml(distance, isEnabled) {
        let escapeName = utils.escape(this.name);
        let escapeDescription = utils.escape(this.description);
        let distanceStr = __classPrivateFieldGet(this, _Term_instances, "m", _Term_distanceToColor).call(this, distance);
        return `
        <div class="container" id="term-${this.id}" style="background-color: #${distanceStr};" id="term-${this.id}">
            ${escapeName}<br/>
            ${escapeDescription}<br/>
            <button id="delete-${this.id}" disabled="${!isEnabled}">Delete</button>
        </div>`;
    }
    toJson() {
        return {
            name: this.name,
            description: this.description,
            relationships: this.relationships.map(x => x.toJson())
        };
    }
}
_Term_instances = new WeakSet(), _Term_distanceToColor = function _Term_distanceToColor(distance) {
    distance *= 40;
    if (distance > 255)
        return "ccc";
    let c = distance.toString(16);
    if (c.length == 1)
        return `0${c}ff$0{c}`;
    return c + "ff" + c;
};
Term.refId = 0;
