var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _a, _SaveData_version;
export default class SaveData {
    constructor(terms, relations) {
        this.terms = terms.map(x => x.toJson());
        this.relations = relations;
        this.version = __classPrivateFieldGet(SaveData, _a, "f", _SaveData_version);
    }
    static getVersion() {
        return __classPrivateFieldGet(SaveData, _a, "f", _SaveData_version);
    }
}
_a = SaveData;
_SaveData_version = { value: "0.0.1" };
